import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import './Unauthorized.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState, useStoreActions } from 'easy-peasy'
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
const Unauthorized = (props) => {
    const classes = useStyles();
    const logout = useStoreActions(action => action.logOut)
    const elogout = useStoreActions(action => action.logOutEncoder)
    const goBack = () =>{
        logout()
        elogout()
        props.history.push('/')
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
            </div>
            <div className='container'>
                <div className="message">
                    <Typography component="h1" variant="h5">
                        This path is blocked.
                    </Typography>
                    <Typography component="p" variant="h5">
                        Maybe you typed the wrong URL?
                    </Typography>
                    
                </div>
                {/* <ReactLink to="/"> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={goBack}
                    >
                        Go Home
                    </Button>
                {/* </ReactLink> */}
            </div>
        </Container>
    )
}

export default Unauthorized;