import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
}))(MuiDialogContent);

export default function ExamCodeHelp(props) {
    // const [open, setOpen] = useState(false);

    return (
        <div>
            <Dialog onClose={props.close} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={props.close}>
                    Exam Code Help
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <h3>What is an Exam Code?</h3>
                        <p>An exam code is a 7-digit code that you will be provided as soon as you have paid for your exams.</p>
                    </Typography>
                    <Typography gutterBottom>
                        <h3>Where do I get an Exam Code?</h3>
                        <p>You can get an exam code in school. The cashier will provide one for you.</p>
                    </Typography>
                    <Typography gutterBottom>
                        <h3>I've already paid. Where is my Exam Code?</h3>
                        <p>Please contact the school immediately so the cashier can provide one for you.</p>
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}