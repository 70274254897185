import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SignIn from './components/SignIn'
import ProtectedRoute from './components/ProtectedRoute'
import ProtectedEncoderRoute from './components/ProtectedEncoderRoute'
import ProtectedOfficeRoute from './components/ProtectedOfficeRoute'
import ProtectedCashierRoute from './components/ProtectedCashierRoute'
import Unauthorized from './components/Unauthorized';
import Loading from './components/Loading';
import Snackbar from '@material-ui/core/Snackbar';
import { useStoreState, useStoreActions } from 'easy-peasy'
const Dashboard = lazy(() => import('./components/Dashboard'))
const ExamPrep = lazy(() => import('./components/ExamPrep'))
const Exam = lazy(() => import('./components/Exam'))
const Office = lazy(() => import('./components/office/SignIn'))
const OfficePanel = lazy(() => import('./components/OfficePanel'))
const OfficeAddExam = lazy(() => import('./components/AddExam'))
const OfficeSubject = lazy(() => import('./components/OfficeSubject'))
const OfficeAddSubject = lazy(() => import('./components/OfficeAddSubject'))
const OfficeEditSubject = lazy(() => import('./components/OfficeEditSubject'))
const OfficeStudent = lazy(() => import('./components/OfficeStudent'))
const OfficeAddStudent = lazy(() => import('./components/OfficeAddStudent'))
const OfficeEditStudent = lazy(() => import('./components/OfficeEditStudent'))
const OfficeSchedule = lazy(() => import('./components/OfficeSchedule'))
const OfficeEditSchedule = lazy(() => import('./components/OfficeEditSchedule'))
const OfficeQuestion = lazy(() => import('./components/OfficeQuestion'))
const OfficeEditQuestion = lazy(() => import('./components/OfficeEditQuestion'))
const OfficeAddQuestion = lazy(() => import('./components/OfficeAddQuestion'))
const OfficeChangePassword = lazy(() => import('./components/OfficeChangePassword'))

const SelectSubject = lazy(() => import('./components/ExamSelectForPreview'))
const PreviewExam = lazy(() => import('./components/PreviewExam'))

const AddDiscounted = lazy(() => import('./components/CashierAddDiscounted'))
const EditDiscounted = lazy(() => import('./components/CashierEditDiscounted'))
const CashierDiscounted = lazy(() => import('./components/CashierDiscounted'))

const AddRate = lazy(() => import('./components/CashierAddRates'))
const EditRate = lazy(() => import('./components/CashierEditRates'))
const CashierRates = lazy(() => import('./components/CashierRates'))

const CashierSettings = lazy(() => import('./components/Settings'))

const AddPending = lazy(() => import('./components/CashierAddPending'))
const EditPending = lazy(() => import('./components/CashierEditPending'))
const CashierPending = lazy(() => import('./components/CashierPending'))
const CashierPanel = lazy(() => import('./components/CashierPanel'))
const Cashier = lazy(() => import('./components/cashier/SignIn'))

const EnrollStatus = lazy(() => import('./components/EnrollmentStatus'))
const Enroll = lazy(() => import('./components/Enroll'))
const ManageEnroll = lazy(() => import('./components/ManageEnrollment'))

const OfficeExamCode = lazy(() => import('./components/OfficeExamCode'))
const OfficeAddExamCode = lazy(() => import('./components/OfficeAddExamCode'))
const OfficeEditExamCode = lazy(() => import('./components/OfficeEditExamCode'))

const Encoder = lazy(() => import('./components/instructors/SignIn'))
const EncoderPanel = lazy(() => import('./components/EncoderPanel'))
const EncoderSubject = lazy(() => import('./components/EncoderSubject'))
const EncoderStudent = lazy(() => import('./components/EncoderStudent'))
const EncoderAddStudent = lazy(() => import('./components/AddStudent'))
const EncoderEditStudent = lazy(() => import('./components/EditStudent'))
const EncoderAddSubject = lazy(() => import('./components/AddSubject'))
const EncoderEditSubject = lazy(() => import('./components/EditSubject'))
const EncoderEditInstructor = lazy(() => import('./components/EditInstructor'))
const EncoderAddInstructor = lazy(() => import('./components/AddInstructor'))
const EncoderInstructor = lazy(() => import('./components/EncoderInstructor'))
const EncoderQuestion = lazy(() => import('./components/EncoderQuestion'))
const EncoderEditQuestion = lazy(() => import('./components/EditQuestion'))
const EncoderAddQuestion = lazy(() => import('./components/AddQuestion'))
const EncoderExamCode = lazy(() => import('./components/EncoderExamCode'))
const EditCode = lazy(() => import('./components/EditCode'))
const AddCode = lazy(() => import('./components/AddCode'))

const EncoderUser = lazy(() => import('./components/EncoderUser'))
const EditUser = lazy(() => import('./components/EditUser'))
const AddUser = lazy(() => import('./components/AddUser'))

const EncoderSchedule = lazy(() => import('./components/EncoderSchedule'))
const EditSchedule = lazy(() => import('./components/EditRestriction'))

const EncoderResult = lazy(() => import('./components/EncoderResult'))
const OfficeResult = lazy(() => import('./components/OfficeResult'))
// const EditSchedule = lazy(() => import('./components/EditRestriction'))

const NotFound = lazy(() => import('./components/NotFound'))

function Routes() {
    let snackbar = useStoreState(s=>s.snackbar)
    let loggedIn = useStoreState(s => s.userCreds.active)
    let setSnackbar = useStoreActions(action => action.setSnackbar)
    let handleClose = () => {
        setSnackbar({open: false, message: ""})
    }

    return (
        <div>
            <Suspense fallback={<Loading />}>
                <Router>
                    <Switch>
                        <Route exact path="/" user={loggedIn} render={props => <SignIn {...props} user={loggedIn.toString()} />}/>
                        <ProtectedRoute exact path="/dashboard/:subject/start" component={Exam} />
                        <ProtectedRoute exact path="/dashboard/:subject" component={ExamPrep} />
                        <ProtectedRoute exact path="/dashboard" component={Dashboard}/>
                        
                        
                        <Route exact path="/enroll/manage/:enrollment" component={EnrollStatus} />
                        <Route exact path="/enroll/new" component={Enroll} />
                        <Route exact path="/enroll" component={ManageEnroll} />
                        
                        {/* add these */}
                        <ProtectedCashierRoute exact path="/cashier/settings" component={CashierSettings} />

                        <ProtectedCashierRoute exact path="/cashier/rates/edit/:rate" component={EditRate} />
                        <ProtectedCashierRoute exact path="/cashier/rates/add" component={AddRate} />
                        <ProtectedCashierRoute exact path="/cashier/rates" component={CashierRates} />
                        
                        <ProtectedCashierRoute exact path="/cashier/discounted/edit/:discounted" component={EditDiscounted} />
                        <ProtectedCashierRoute exact path="/cashier/discounted/add" component={AddDiscounted} />
                        <ProtectedCashierRoute exact path="/cashier/discounted" component={CashierDiscounted} />

                        <ProtectedCashierRoute exact path="/cashier/pending/edit/:pending" component={EditPending} />
                        <ProtectedCashierRoute exact path="/cashier/pending/add" component={AddPending} />
                        <ProtectedCashierRoute exact path="/cashier/pending" component={CashierPending} />

                        <ProtectedCashierRoute exact path="/cashier/enrollment" component={Cashier} />
                        
                        <ProtectedCashierRoute exact path="/cashier" component={CashierPanel} />
                        <Route exact path="/cashier/login" component={Cashier} />

                        <ProtectedOfficeRoute exact path="/test/select/:id" component={PreviewExam} /> 
                        <ProtectedOfficeRoute exact path="/test/select" component={SelectSubject} /> 
                        <ProtectedOfficeRoute exact path="/manage/change-password" component={OfficeChangePassword} />

                        <ProtectedOfficeRoute exact path="/manage/questions/edit/:question" component={OfficeEditQuestion} /> 
                        <ProtectedOfficeRoute exact path="/manage/questions/add" component={OfficeAddQuestion} /> 
                        <ProtectedOfficeRoute exact path="/manage/questions" component={OfficeQuestion} /> 

                        <ProtectedOfficeRoute exact path="/manage/codes" component={OfficeExamCode} />
                        <ProtectedOfficeRoute exact path="/manage/codes/add" component={OfficeAddExamCode} />
                        <ProtectedOfficeRoute exact path="/manage/codes/edit/:code" component={OfficeEditExamCode} />
                        
                        <ProtectedOfficeRoute exact path="/manage/schedules" component={OfficeSchedule} />
                        <ProtectedOfficeRoute exact path="/manage/schedules/edit/:schedule" component={OfficeEditSchedule} />

                        <ProtectedOfficeRoute exact path="/manage/students/edit/:student" component={OfficeEditStudent} />
                        <ProtectedOfficeRoute exact path="/manage/students/add" component={OfficeAddStudent} />
                        <ProtectedOfficeRoute exact path="/manage/students" component={OfficeStudent} />

                        <ProtectedOfficeRoute exact path="/manage/subjects/edit/:subject" component={OfficeEditSubject} />
                        <ProtectedOfficeRoute exact path="/manage/subjects/add" component={OfficeAddSubject} />
                        <ProtectedOfficeRoute exact path="/manage/subjects" component={OfficeSubject} />

                        <ProtectedOfficeRoute exact path="/manage/exam/add" component={OfficeAddExam} />

                        <ProtectedOfficeRoute exact path="/manage/results" component={OfficeResult} />
                        <ProtectedOfficeRoute exact path="/manage/" component={OfficePanel} />
                        
                        <Route exact path="/manage/login" component={Office} />

                        <ProtectedEncoderRoute exact path="/admin/control/subjects/edit/:subject" component={EncoderEditSubject} />
                        <ProtectedEncoderRoute exact path="/admin/control/subjects/add" component={EncoderAddSubject} />
                        <ProtectedEncoderRoute exact path="/admin/control/subjects" component={EncoderSubject} />

                        <ProtectedEncoderRoute exact path="/admin/control/students/edit/:student" component={EncoderEditStudent} />
                        <ProtectedEncoderRoute exact path="/admin/control/students/add" component={EncoderAddStudent} />
                        <ProtectedEncoderRoute exact path="/admin/control/students" component={EncoderStudent} />

                        <ProtectedEncoderRoute exact path="/admin/control/instructors/edit/:instructor" component={EncoderEditInstructor} />
                        <ProtectedEncoderRoute exact path="/admin/control/instructors/add" component={EncoderAddInstructor} />
                        <ProtectedEncoderRoute exact path="/admin/control/instructors" component={EncoderInstructor} />

                        <ProtectedEncoderRoute exact path="/admin/control/questions/edit/:question" component={EncoderEditQuestion} />
                        <ProtectedEncoderRoute exact path="/admin/control/questions/add" component={EncoderAddQuestion} />
                        <ProtectedEncoderRoute exact path="/admin/control/questions" component={EncoderQuestion} />
                        
                        <ProtectedEncoderRoute exact path="/admin/control/codes" component={EncoderExamCode} />
                        <ProtectedEncoderRoute exact path="/admin/control/codes/add" component={AddCode} />
                        <ProtectedEncoderRoute exact path="/admin/control/codes/edit/:code" component={EditCode} />

                        <ProtectedEncoderRoute exact path="/admin/control/users" component={EncoderUser} />
                        <ProtectedEncoderRoute exact path="/admin/control/users/add" component={AddUser} />
                        <ProtectedEncoderRoute exact path="/admin/control/users/edit/:user" component={EditUser} />

                        <ProtectedEncoderRoute exact path="/admin/control/schedules" component={EncoderSchedule} />
                        <ProtectedEncoderRoute exact path="/admin/control/schedules/edit/:schedule" component={EditSchedule} />

                        <ProtectedEncoderRoute exact path="/admin/control/results" component={EncoderResult} />
                        <ProtectedEncoderRoute exact path="/admin/control/" component={EncoderPanel} />
                        <Route exact path="/admin/login" component={Encoder} />
                        <Route exact path='/unauthorized' component={Unauthorized} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Router>
            </Suspense>
            <Snackbar
            anchorOrigin={snackbar.directions}
            open={snackbar.open}
            onClose={handleClose}
            message={snackbar.message}
            key={snackbar.message}
            autoHideDuration={2000}
            />
        </div>
    );
}



export default Routes;