import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import * as SVGLoaders from 'svg-loaders-react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
    },
    text: {
        padding: theme.spacing(12, 2, 0),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100vh'
    }
}));

const Loading = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Paper square className={classes.paper}>
                <SVGLoaders.Bars stroke="#4959b1" fill="#4959b1" strokeOpacity="1"/>
                <p>Preparing data...</p>
            </Paper>
            
        </div>
    )

};

export default Loading;

