import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SignInHelp from './SignInHelp'
import ExamCodeHelp from './ExamCodeHelp'
import lf from 'localforage'
import { useStoreActions, useStoreState } from 'easy-peasy'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import Collapse from '@material-ui/core/Collapse';

import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    select: {
        margin: theme.spacing(2,0)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    bottom: {
        margin: theme.spacing(0, 0, 9),
    }
}));

export default function SignIn(props) {
    
    const login = useStoreActions(actions => actions.login)
    const classes = useStyles();
    const userCreds = useStoreState(state => state.userCreds)
    const host = useStoreState(state => state.host)
    const setHost = useStoreActions(action => action.setHost)
    const [loading, setLoading] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [enrollment, setEnrollment] = useState(false)
    const [showExamCodeHelp, setShowExamCodeHelp] = useState(false)
    // const examCodeExp = useStoreState(state => state.examCodeExp)
    // const setLogin = useStoreActions(action => action.setUserCreds)
    const activeExam = useStoreState(state => state.codeState)
    const currentSubject = useStoreState(state => state.currentSubject)
    const pullActiveExam = useStoreActions(actions => actions.pullActiveExam)
    // const setExp = useStoreActions(action => action.setExamCodeExp)
    const setSnackbar = useStoreActions(action => action.setSnackbar)
    const [values, setValues] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        suffix: "",
        gradeLevel: "",
        lrn: "",
        examCode: "",
        showCode: false
    })

        // const checkForActiveExam = () => {
    
    const [tab, setTab] = useState(0);

    const handleTab = (event, newValue) => {
        setTab(newValue);
    };
    const getHostURL = async () => {
        // let response = await axios.get('https://clear.adfcollege.net/current')
        // console.log(response.data)
        // let {Url} = response.data[0]
        
        // setHost(`https://${Url}`)
        setHost(`https://steady.adfcollege.net`)
        // setHost(`https://basiced-adfc.hotellarica.com`)
    }

    const getSettings = async () => {
        let response = await axios.get(`${host}/api/settings`)
        
        let en = response.data.message.enrollment

        setEnrollment(en)
    }

    useEffect(() => {
        getSettings()
        // if(!host) {
            getHostURL()
        // }

        if(userCreds.active){
            if (activeExam) {
                (async function checkActiveExam() {
                    let active = await pullActiveExam(activeExam);
                    
                    if(active.success){
                        props.history.push(`/dashboard/${currentSubject._id}/start`)
                    }
                })();
                
            } else {
                props.history.push('/dashboard')
            }
        } 
        
    },[])
    let handleChange = (prop) => {
        const {value, id} = prop.target
        setValues({...values, [id]: value })
    }

    let handleSubmit = async (e) =>{
        e.preventDefault();
        setLoading(true)
        
        console.log(values)
        console.log(tab)
        
        if(tab == 1){
            if (tab == 1 && !values.firstName || !values.middleName || !values.lastName || !values.gradeLevel || !values.examCode) {
                let required1 = ['firstName', 'lastName', 'gradeLevel', 'examCode']
                let props = []
                let held = { ...values }
                let missing = []
                for (let prop in held) {
                    if (required1.includes(prop)) {
                        if (!held[prop]) {
                            missing.push(prop.toUpperCase())
                        }
                    }
                }
                
                setLoading(false)
                return setSnackbar({ open: true, message: `Please enter your ${missing.join(', ')}`.trim() })

            } 
            values.lrn = ''
        }
        
        if (tab == 0){
            if (tab == 0 && !values.lrn || !values.gradeLevel || !values.examCode) {
                let required = ['lrn', 'gradeLevel', 'examCode']
                let props = []
                let held = { ...values }
                let missing = []
                for (let prop in held) {
                    if (required.includes(prop)) {
                        if (!held[prop]) {
                            missing.push(prop.toUpperCase())
                        }
                    }
                }
                setLoading(false)
                return setSnackbar({ open: true, message: `Please enter your ${missing.join(', ')}`.trim() })

            }

            values.firstName = ''
            values.middleName = ''
            values.lastName = ''
            values.suffix = ''
        }
        

        let res = await login({...values, type: tab})
        
        if(!res.success) {
            setSnackbar({open: true, message: res.message})
            setLoading(false)
        } else {
            
            setLoading(false)
            if(res.activeExam){
                let exam = await pullActiveExam(res.aed)
                if(exam.success){
                    
                    props.history.push(`/dashboard/${exam.subject._id}/start`)
                }
                
            } else {
                props.history.push('/dashboard')
            }
            // login({...values, type: tab})
            setSnackbar({ open: true, message: res.message })
            
            
        }
        
    }
    const handleClickShowCode = () => {
        
        setValues((old)=>{
            return {
                ...old,
                showCode: !old.showCode
            }
        })
    };
    const handleMouseDownCode = (event) => {
        event.preventDefault();
    };

    const handleCloseHelp = () =>{
        setShowHelp(false)
    }

    const handleCloseExamCodeHelp = () => {
        setShowExamCodeHelp(false)
    }
    const [open, setOpen] = React.useState(true);
    const handleEnroll = () => {
        console.log('clicked enroll')
        props.history.push(`/enroll`)
    }
    return (
        <Container component="main" maxWidth="xs">
            {
                enrollment ? (<Alert onClick={handleEnroll}>
                    Enrollment has started! Click here to enroll.
                </Alert>): ""
            }
            
            <SignInHelp close={handleCloseHelp} open={showHelp} />
            <ExamCodeHelp close={handleCloseExamCodeHelp} open={showExamCodeHelp} />
            <CssBaseline />
            <div className={classes.paper}>
                
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Tabs
                    value={tab}
                    onChange={handleTab}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Using LRN" />
                    <Tab label="Using Personal Info" />
                </Tabs>
                {tab == 0 ? (<form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="lrn"
                        value={values.lrn}
                        label="LRN"
                        onChange={handleChange}
                        name="lrn"
                    />
                    {/* <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="gradeCategory"
                                select
                                label="Level Category"
                                value={values.gradeCategory}
                                onChange={handleChange}
                                SelectProps={{
                                    native: true,
                                }}
                                className={classes.select}
                                helperText="Please select your level category"
                                variant="outlined"
                            >
                                {currencies.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            
                        </Grid>
                        
                    </Grid> */}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="gradeLevel"
                        value={values.gradeLevel}
                        label="Grade Level"
                        helperText="Ex. [Nursery 1 = N1], [Kinder 1 = K1], [Grade 3 = G3]"
                        onChange={handleChange}
                        name="gradeLevel"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type={values.showCode ? 'text' : 'password'}
                        value={values.examCode}
                        name="examCode"
                        label="Exam Code"
                        onChange={handleChange}
                        id="examCode"
                        autoComplete="current-password"
                        // helperText="Enter the exam code you got from school"
                        InputProps={{
                            endAdornment:
                                (< InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowCode}
                                        onMouseDown={handleMouseDownCode}
                                        edge="end"
                                    >
                                        {values.showCode ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </ InputAdornment>)
                        }}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? (<CircularProgress size={25} color="white" />) : "Login"}
                    </Button>

                    <Grid container className={classes.bottom}>
                        <Grid item xs>
                            <Link href="#" variant="body2" onClick={()=>{setShowHelp(true)}}>
                                {"Help"}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2" onClick={() => { setShowExamCodeHelp(true) }}>
                                {"Don't have an exam code?"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>) : (<form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        value={values.firstName}
                        label="First Name / Given Name"
                        name="First Name"
                        onChange={handleChange}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={values.middleName}
                        id="middleName"
                        label="Middle Initial"
                        helperText="Don't add a period next to your middle initial"
                        onChange={handleChange}
                        name="Middle Name"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={values.lastName}
                        id="lastName"
                        label="Last Name / Family Name"
                        onChange={handleChange}
                        name="Last Name"
                    />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            value={values.suffix}
                            id="suffix"
                            label="Generational Suffix"
                            helperText="Ex. Jr, Sr, III, IV, V"
                            onChange={handleChange}
                            name="Suffix"
                        />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={values.gradeLevel}
                        id="gradeLevel"
                        label="Grade Level"
                        onChange={handleChange}
                        helperText="Ex. [Nursery 1 = N1], [Kinder 1 = K1], [Grade 3 = G3]"
                        name="Grade Level"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type={values.showCode ? 'text' : 'password'}
                        value={values.examCode}
                        name="examCode"
                        label="Exam Code"
                        onChange={handleChange}
                        id="examCode"
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment:
                                (< InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowCode}
                                        onMouseDown={handleMouseDownCode}
                                        edge="end"
                                    >
                                        {values.showCode ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </ InputAdornment>)
                        }}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                            {loading ? (<CircularProgress size={25} color="white" />) : "Login"}
                    </Button>

                    <Grid container className={classes.bottom}>
                        <Grid item xs>
                            <Link href="#" variant="body2" onClick={()=>{setShowHelp(true)}}>
                                {"Help"}
                            </Link>
                        </Grid>
                        <Grid item>
                                <Link href="#" variant="body2" onClick={() => { setShowExamCodeHelp(true) }}>
                                {"Don't have an exam code?"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>) }
                
            </div>
            
        </Container>
    );
}
