import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function SignInHelp(props) {
    // const [open, setOpen] = useState(false);

    return (
        <div>
            <Dialog onClose={props.close} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={props.close}>
                    Quick Help
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <p>This is the sign in page for your online exam. This short guide will help you understand how to use
                        the text boxes and buttons on this page.</p>
                    </Typography>
                    <Typography gutterBottom>
                        <p>There are currently 2 ways to sign in: </p>
                        <ul>
                                <li>Using your LRN</li>
                                <li>Using some of your personal information</li>
                        </ul>
                        <p>You are free to choose whichever one you'd like to use to sign in.  Simply click either <u>Using LRN</u> or <u>Using personal info</u> found below the lock icon.
                        </p>
                    </Typography>
                    <Typography gutterBottom>
                        <p>Clicking on either one will cause the page to change and require different information from you in order to sign you into the app.</p>
                        <p><strong>"Using LRN"</strong> requires the least number of info but it requires your LRN, which is a 12 digit number assigned by DepEd to a
                        student during his basic education.</p>
                        <p><strong>"Using personal info"</strong> requires you to enter the following info: </p>
                        <ul>
                            <li>First Name</li>
                            <li>Middle Name</li> 
                            <li>Last Name</li>
                            <li>Generational Suffix (if you have one)</li>
                            <li>Grade Level</li>
                            <li>Exam Code</li>
                        </ul>
                        
                        <p>These are all required to ensure that the system does not mistake you for someone else.</p>
                        <p>Note: The <em>generational suffix</em> does not require a period "." at the end of it. So you can just enter <em>Jr</em>, <em>Sr</em>, III, IV, V, etc.</p>
                    </Typography>
                    <Typography gutterBottom>
                        <p><strong>Exam Codes</strong> are important and must be kept safe. It's <em>hidden by default</em> in the exam code text box but if you would like to see what you have entered, you can <em>press the eye icon</em> found near the right edge of the exam code textbox</p>
                    </Typography>
                    <Typography gutterBottom>
                        <p>You may choose to type your personal info in UPPERCASE, Capitalize, or lowercase forms. Please review the details you've entered before pressing the login button.</p>
                    </Typography>
                    <Typography gutterBottom>
                        <p>We've done our best to make sure that the app is <em>glitch-free</em> but unexpected errors can happen. If you ever find errors, bugs, or glitches <strong>please let the school know immediately</strong>.</p>
                    </Typography>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Save changes
                    </Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}