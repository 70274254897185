import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStoreState } from 'easy-peasy'
import lf from 'localforage'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    
    let user = useStoreState(state=>state.userCreds.active)

    return (
        <Route {...rest} render={
            
            props => {
                if(user){
                    return <Component {...rest} {...props} user={user} />
                } else {
                    return <Redirect to={
                        {pathname: "/unauthorized", state: {from: props.location}}
                    } />
                }
            }
            
        } />
    )
}

export default ProtectedRoute;