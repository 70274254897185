import { thunk, action, persist } from 'easy-peasy'
import axios from 'axios'

// import lf from 'localforage'

export default (persist({
    userCreds: {
        token: "",
        active: false
    },
    snackbar: {
        open: false,
        message: "",
        directions: {
            vertical: "top",
            horizontal: "center"
        }
    },
    enrollmentToken: "",
    setEnrollmentToken: action((state, payload) => {
        state.enrollmentToken = payload
    }),
    setRestrictions: action((state, payload)=>{
        state.userCreds.restrictions = payload
    }),
    instructorCreds: {},
    encoderCreds: {},
    setSnackbar: action((state,payload)=>{
        state.snackbar["open"] = payload.open;
        state.snackbar["message"] = payload.message;
    }),
    codeState: "",
    setCodeState: action((state, payload) => {
        state.codeState = payload
    }),
    host: "",
    setHost: action((state, payload) => {
        state.host = payload
    }),
    examCodeExp: "",
    subjects: [],
    setSubjects: action((state,payload)=>{
        state.subjects = payload
    }),
    currentExamExp: "",//time,
    currentExamQuestions: "",
    setCurrentExamQuestions: action((state,payload)=>{
        state.currentExamQuestions = payload
    }),
    attachAnswersToQuestion: action((state, payload) => {
        state.currentExamQuestions = [...state.currentExamQuestions].map (question => {
            if (question._id == payload.question) {
                return Object.assign(question, { answer: payload.answer })
            } else {
                return question
            }
        })
    }),
    
    setCurrentExamExp: action((state, payload) => {
        state.currentExamExp = payload
    }),
    setUserCreds: action((state,payload)=>{
        state.userCreds = {
            token: payload.token,
            restrictions: payload.restrictions,
            active: true
        }
    }),
    setEncoderCreds: action((state, payload) => {
        state.userCreds = {
            token: payload.token,
            access: payload.access,
            user: payload.user,
            active: true
        }
    }),
    currentSubject: "",
    setCurrentSubject: action((state, payload) => {
        state.currentSubject = payload
    }),
    setExamCodeExp: action((state, payload) => {
        let dt = new Date(payload)
        dt.setHours(23, 59, 59, 999)
        state.examCodeExp = dt.getTime()
    }),
    
    loginEncoder: thunk(async (actions, payload, { getStoreState }) => {
        if (payload) {
            if (!payload.user && !payload.pass) {
                console.log('Please provide username and password')
            }
        } else {
            console.log('Payload empty')
        }
        
        
        let { user, pass } = payload
        
        let loggedInResponse = await axios({
            method: 'post',
            url: `${getStoreState().host}/api/users/login`,
            timeout: 20000,
            data: {
                user: {user, pass}
            }
        }).catch(function (error) {
            return error.message
        });

        if(typeof loggedInResponse == 'string'){
            if(loggedInResponse.match(/error/gi)){
                return {
                    success: false,
                    message: loggedInResponse
                }
            }
        }
        let { data: {success, user: data} } = loggedInResponse
        
        if (success) {            
            actions.setEncoderCreds({ ...data, active: true })
            return {
                success: true,
                message: "Login Successful"
            }
        } else {
            return { success: false, message: "Something went wrong" }
        }
    }),
    verifyEnrollment: thunk(async (actions, payload, { getStoreState }) => {

        if (!payload.firstName || !payload.lastName || !payload.refCode) {
            console.log('Please provide necessary information')
        }

        let { firstName, lastName, refCode } = payload

        console.log(firstName, lastName, refCode)

        let loggedInResponse = await axios({
            method: 'post',
            url: `${getStoreState().host}/api/enroll/verify`,
            timeout: 20000,
            data: {
                firstName, lastName, refCode
            }
        }).catch(function (error) {
            return error.message
        });

        if (typeof loggedInResponse == 'string') {
            if (loggedInResponse.match(/error/gi)) {
                return {
                    success: false,
                    message: loggedInResponse
                }
            }
        }

        
        console.log(loggedInResponse.data)
        let { success, message, data: {token,_id} } = loggedInResponse.data
        if (success) {
            
            actions.setEnrollmentToken(token)

            return {
                success: true,
                enrollment: _id,
                message
            }

        } else {
            return { success: false, message }
        }
    }),
    login: thunk(async (actions, payload, { getStoreState }) => {
        
        if (payload.type == 0){
            if(!payload.lrn && !payload.gradeLevel && !payload.examCode) {
                console.log('Provide log in information')
            }
        } else {
            if (!payload.firstName && !payload.middleName && !payload.lastName && !payload.gradeLevel && !payload.examCode) {
                console.log('Provide log in information')
            }
        }
            
        
        let { firstName, middleName, lastName, lrn, suffix, gradeLevel, examCode, type } = payload
        
        
        let loggedInResponse = await axios({
            method: 'post',
            url: `${getStoreState().host}/api/students/verify`,
            timeout: 600000,
            data: {
                firstName, middleName, lastName, lrn, suffix, gradeLevel, examCode, type
            }
        }).catch(function (error) {
            return error.message
        });

        if (typeof loggedInResponse == 'string') {
            if (loggedInResponse.match(/error/gi)) {
                return {
                    success: false,
                    message: loggedInResponse
                }
            }
        }

        let  {data} = loggedInResponse
        if(data.success){
            let {validUntil, restrictions, token, activeExam} = data.data
            
            if(activeExam){
                actions.setCodeState(activeExam)
            }

            actions.setUserCreds({token, restrictions})
            actions.setExamCodeExp(validUntil)

            return {
                activeExam: activeExam?true:false,
                aed: activeExam,
                success: true,
                message: "Login Successful"
            }
        } else {
            return {success: false, message: data.message}
        }
        
        // const updated = await productService.update(payload.id, payload);
        // actions.setProduct(updated); // 👈 dispatch local actions to update state
    }),
    updateRemainingTime: thunk(async (actions, payload, { getStoreState }) => {
        let processedSubjects = await axios({
            method: 'put',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            
            url: `${getStoreState().host}/api/subjects/update/expiration`,
            data: payload
        })

        let { data } = processedSubjects
        let { success, message: response } = await data
        
        
    }),
    releaseLock: thunk(async (actions, payload, { getStoreState }) => {

        let processedSubjects = await axios({
            method: 'post',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 20000,
            url: `${getStoreState().host}/api/exam-codes/release/`,
            data: payload
        }).catch(err => {
            return {
                success: false,
                message: "error"
            }
        })

        console.log(processedSubjects)

        if (!processedSubjects.hasOwnProperty('data')) {
            if (!processedSubjects.success) {
                return {
                    success: false,
                    message: "Request Timeout. Failed to connect to server."
                }
            }
        }

        let { data } = processedSubjects

        let { success, data: subject } = await data

        if (success) {

            actions.setCurrentExamQuestions("")
            actions.setCurrentExamExp("")
            actions.setCodeState("")
            actions.setCurrentSubject("")

            return { success: true, message: "Exam already submitted" }
        } else {
            return { success: false, message: data.message }
        }
    }),
    getInstructor: thunk(async (actions, payload, { getStoreState }) => {
        let processedSubjects = await axios({
            method: 'get',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 20000,
            url: `${getStoreState().host}/api/instructors/find/${payload.firstName}-${payload.lastName}`
        }).catch(function (error) {
            return {
                success: false,
                message: "error"
            }
        });
        
        if(processedSubjects.data.success){
            return { success: true, message: processedSubjects.data.message}
        }
        
    }),
    uploadNewExam: thunk(async (actions, payload, { getStoreState }) => {
        let processedSubjects = await axios({
            method: 'post',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 20000,
            url: `${getStoreState().host}/api/subjects/exam`,
            data: payload
        }).catch(function (error) {
            return {
                success: false,
                message: "error adding an exam"
            }
        });
        
        if (processedSubjects) {
            if(processedSubjects.data){
                return {
                    message: processedSubjects.data.message
                }
            }
        }

    }),
    getExamCodePaginate: thunk(async (actions, payload, { getStoreState }) => {
        let processedSubjects = await axios({
            method: 'get',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 20000,
            url: `${getStoreState().host}/api/exam-codes/paginate/q?search=${payload.search}&limit=${payload.pageSize}&page=${+(payload.page)}`
        }).catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: "error"
            }
        });
        // console.log(processedSubjects)
        return processedSubjects
    }),
    getQuestionPaginate: thunk(async (actions, payload, { getStoreState }) => {
        let processedSubjects = await axios({
            method: 'get',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 600000,
            url: `${getStoreState().host}/api/questions/paginate/q?search=${payload.search}&limit=${payload.pageSize}&page=${+(payload.page)}`
        }).catch(function (error) {
            // console.log(error)
            return {
                success: false,
                message: "error"
            }
        });
        console.log(processedSubjects)
        return processedSubjects
    }),
    getResultsPaginate: thunk(async (actions, payload, { getStoreState }) => {
        let processedSubjects = await axios({
            method: 'get',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 600000,
            url: `${getStoreState().host}/api/results/paginate/q?search=${payload.search}&limit=${payload.pageSize}&page=${+(payload.page)}`
        }).catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: "error"
            }
        });
        // console.log(processedSubjects)
        return processedSubjects
    }),
    getActive: thunk(async (actions, payload, { getStoreState }) => {
        let processedSubjects = await axios({
            method: 'get',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 600000,
            url: `${getStoreState().host}/api/exam-codes/active`
        }).catch(function (error) {
            return {
                success: false,
                message: "error"
            }
        });

        // console.log(processedSubjects.data.data[0].subject)
        if(processedSubjects){
            if(processedSubjects.data){
                if(processedSubjects.data.data[0]){
                    if (processedSubjects.data.data[0].subject){
                        return { success: true, message: "Successfully retrieved active", data: processedSubjects.data.data[0].subject }
                    }
                }
            }
        }
        
    }),

    submitExam: thunk(async (actions, payload, { getStoreState }) => {
        let processedSubjects = await axios({
            method: 'post',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 600000,
            url: `${getStoreState().host}/api/subjects/submit/`,
            data: payload
        }).catch(err => {
            return {
                success: false,
                message: "error"
            }
        })
        
        // if (typeof processedSubjects == 'string') {
        //     if (processedSubjects.match(/error/gi)) {
        //         return {
        //             success: false,
        //             message: processedSubjects
        //         }
        //     }
        // }

        if (!processedSubjects.hasOwnProperty('data')) {
            if (!processedSubjects.success) {
                return {
                    success: false,
                    message: "Request Timeout. Failed to connect to server."
                }
            }
        }

        let { data } = processedSubjects
        
        let { success, data: subject } = await data

        let restrictions = getStoreState().userCreds.restrictions
        let currentSubject = getStoreState().currentSubject
        let availableSubjects = []
        
        for (let restriction of restrictions) {
            for (let subj of restriction.visibleSubjects) {
                availableSubjects.push(subj.subject)
            }
        }

        if (!availableSubjects.includes(currentSubject._id)) {
            return { success: false, message: "Subject is not available to you" }
        }

        restrictions.forEach((restriction, rIndex) => {
            restriction.visibleSubjects.forEach((subj, sIndex) => {
                if (subj.subject == currentSubject._id) {
                    restrictions[rIndex].visibleSubjects[sIndex].completed = true
                }
            })
        })

        actions.setRestrictions(restrictions)

        console.log(restrictions)

        if (success) {

            actions.setCurrentExamQuestions("")
            actions.setCurrentExamExp("")
            actions.setCodeState("")
            // actions.setCurrentSubject("")

            return { success: true, message: "Successfully submitted answers" }
        } else {
            return { success: false, message: data.message }
        }
    }),
    pullActiveExam: thunk(async (actions, payload, { getStoreState }) => {
        
        let processedSubjects = await axios({
            method: 'get',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 600000,
            url: `${getStoreState().host}/api/subjects/restore/${payload}`,
        }).catch(function (error) {
            return error.message
        });

        console.log(processedSubjects)

        if (typeof processedSubjects == 'string') {
            if (processedSubjects.match(/error/gi)) {
                return {
                    success: false,
                    message: processedSubjects
                }
            }
        }

        let {data} = await processedSubjects
        console.log(data)
        let { success, data: {subject, state, timeRemaining} } = await data

        
        if (success) {
            
            actions.setCurrentSubject(subject)
            actions.setCurrentExamQuestions(state)
            actions.setCurrentExamExp(timeRemaining)

            return {
                success: true,
                subject: subject,
                message: "Got subject Info"
            }
        } else {
            return { success: false, message: "Error retrieving subject info" }
        }
    }),
    getSubjectInfo: thunk(async (actions, payload, { getStoreState }) => {
        // let { firstName, lastName, examCode } = payload
        
        let processedSubjects = await axios({
            method: 'get',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 20000,
            url: `${getStoreState().host}/api/subjects/${payload}/prep`,
        }).catch(function (error) {
            return error.message
        });
        console.log(processedSubjects)
        if (typeof processedSubjects == 'string') {
            if (processedSubjects.match(/error/gi)) {
                return {
                    success: false,
                    message: processedSubjects
                }
            }
        }

        let { data } = processedSubjects
        let { success, data: subject } = await data
        
        
        if (success) {
            
            actions.setCurrentSubject(subject)

            return {
                success: true,
                message: "Got subject Info"
            }
        } else {
            return { success: false, message: "Error retrieving subject info" }
        }
    }),
    getQuestions: thunk(async (actions, payload, { getStoreState }) => {
        // let { firstName, lastName, examCode } = payload
        
        let processedSubjects = await axios({
            method: 'get',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 600000,
            url: `${getStoreState().host}/api/subjects/${payload}/start`,
        }).catch(err=>{
            return {
                success: false,
                message: "error"
            }
        })

        console.log('processedSubjects', processedSubjects)
        if(!processedSubjects.hasOwnProperty('data')){
            if (!processedSubjects.success) {
                return {
                    success: false,
                    message: "Request Timeout. Failed to connect to server."
                }
            }
        }

        

        let { data } = processedSubjects
        console.log(data)

        
        let { success, message } = await data
        
        if (success) {
            if(message){
                if (message.split(' ').includes('already')) {
                    let restrictions = getStoreState().userCreds.restrictions
                    let currentSubject = getStoreState().currentSubject
                    let availableSubjects = []

                    for (let restriction of restrictions) {
                        for (let subj of restriction.visibleSubjects) {
                            availableSubjects.push(subj.subject)
                        }
                    }

                    if (!availableSubjects.includes(currentSubject._id)) {
                        return { success: false, message: "Subject is not available to you" }
                    }

                    restrictions.forEach((restriction, rIndex) => {
                        restriction.visibleSubjects.forEach((subj, sIndex) => {
                            if (subj.subject == currentSubject._id) {
                                restrictions[rIndex].visibleSubjects[sIndex].completed = true
                            }
                        })
                    })

                    actions.setRestrictions(restrictions)

                    console.log(restrictions)
                    
                    actions.setCurrentExamQuestions("")
                    actions.setCurrentExamExp("")
                    actions.setCodeState("")
                    return {
                        success: true,
                        message: "You have already submitted this exam"
                    }
                }
            } else {
                let {data: {questions, timeRemaining, activeExam}} = data    
                if (!getStoreState().codeState) {
                    actions.setCodeState(activeExam)
                }
                actions.setCurrentExamQuestions(questions)
                actions.setCurrentExamExp(timeRemaining)

                return {
                    success: true,
                    message: "Got questions for exam"
                }
            }
            
        } else {
            return { success: false, message }
        }
    }),
    pullSubjects: thunk(async (actions, payload, { getStoreState }) => {
        // let { firstName, lastName, examCode } = payload

        
        let processedSubjects = await axios({
            method: 'post',
            headers: {
                authorization: `Bearer ${getStoreState().userCreds.token}`
            },
            timeout: 600000,
            url: `${getStoreState().host}/api/subjects/pull`,
            data: payload
        }).catch(function (error) {
            return error.message
        });

        if (typeof processedSubjects == 'string') {
            if (processedSubjects.match(/error/gi)) {
                return {
                    success: false,
                    message: processedSubjects
                }
            }
        }

        let { data } = processedSubjects
        let {  success, data: subjects } = await data
        
        if (success) {
            let addToModel = []
            subjects.map(({completed, subject: {examTimeLimit, gradeLevel, name, _id}})=>{
                addToModel.push({
                    examTimeLimit,
                    _id,
                    gradeLevel,
                    name,
                    done: completed
                })
            })
            
            actions.setSubjects(addToModel)
            return {
                success: true
            }
        } else {
            return { success: false }
        }
    }),
    logOut: action((state, payload) => {
        state.userCreds = {
            token: "",
            restrictions: [],
            active: false
        }
    }),
    logOutEncoder: action((state, payload) => {
        console.log('logged out')
        state.userCreds = {
            token: "",
            access: undefined,
            active: false
        }
    })
}))