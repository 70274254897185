import React, {useState} from 'react';
import './App.css';
import { StoreProvider, createStore, useStoreRehydrated } from 'easy-peasy'

import model from './model';
import Routes from './Routes'
const store = createStore(model)

function WaitForStateRehydration({ children }) {
  const isRehydrated = useStoreRehydrated();
  return isRehydrated ? children : null;
}

function App() {
  return (
    <div>
      <StoreProvider store={store}>
        <WaitForStateRehydration>
          <Routes />
        </WaitForStateRehydration>
      </StoreProvider>
    </div>
  );
}

export default App;